export const errorRefId = {
  prefix: `REF`,
  delimeter: `-`,
  validateIDVRequest: `VR`,
  validateIDVDocument: `VD`,
  cidPar: `CP`,
  cidParToken: `CPT`,
};

export const clientErrors = {
  Required_Field: `Required field.`,
  Name_invalid : 'Invalid input. Please use alphabetic characters. Only spaces, hyphens or apostrophes are allowed. Diacritical marks must be entered as English alphabets (example: è as e).',
  New_Name_invalid : 'Invalid input. Please use alpha characters (upper and lowercase), hyphens, apostrophes and spaces, numeric only. Diacritical marks must be entered as English alphabets (example: è as e).',
  Name_invalid_length : 'Invalid input. Name must not exceed 40 characters.',
  firstName_invalid_length : 'Invalid input. Given name must not exceed 40 characters.',
  middleName_invalid_length : 'Invalid input. Middle name must not exceed 40 characters.',
  familyName_invalid_length : 'Invalid input. Family name must not exceed 50 characters.',
  Name_invalid_Duplicate : 'Invalid input. Please enter your name without titles, acronyms, or post-nominal.',
  Date_invalid: 'Invalid input. Enter a valid date in the format DD MM YYYY. Numbers only.',
  G_Date_invalid: 'Invalid input. Enter a valid date in the format MM YYYY. Numbers only.',
  DOB_empty:'Required field. Enter a valid date of birth in the format DD MM YYYY.',
  DOB_invalid: 'Invalid input. Enter a valid date in the format DD MM YYYY. ',
  DOB_MinError: 'Invalid input. You must be at least 10 years old to activate the service.',
  DOB_MaxError: 'Invalid input. The service owner must be under 120 years old.',
  DOE_GreenCard: 'Invalid input. Please use format MM/YYYY.',
  DOE_pastExpiry: "Invalid input. Date should not be in the past.",
  DOE_PP_pastExpiry:"Invalid input. Expiry date must not be more than three years in the past.",
  DOE_postExpiry: "Invalid input. Please enter a valid expiry date (can't be more than 10 years).",
  Select_empty: 'Required field. Please select an option.',
  State_empty: 'Please select a state.',
  PP_Num_AUS_invalid: 'Invalid input. Format should be 1 or 2 letters followed by 7 digits.',
  PP_Num_INT_invalid: 'Invalid input. Format should be between 1 to 14 letters and/or numbers. No space or special characters.',
  DL_ACT_invalid: 'Invalid input. Enter 1 to 10 numbers only.',
  DL_NSW_invalid: 'Invalid input. Enter 6 to 8 numbers and/or letters only.',
  DL_NT_invalid:'Invalid input. Enter 1 to 10 numbers only.',
  DL_QLD_invalid:'Invalid input. Enter 8 to 9 numbers only.',
  DL_SA_invalid:'Invalid input. Enter 6 numbers and/or letters only.',
  DL_TAS_invalid:'Invalid input. Enter 6 to 8 numbers and/or letters only.',
  DL_VIC_invalid:'Invalid input. Enter 1 to 10 numbers only.',
  DL_WA_invalid:'Invalid input. Enter 7 numbers only.',
  DL_card_ACT_invalid: 'Invalid input. Enter 10 numbers and/or letters only.',
  DL_card_NSW_invalid: 'Invalid input. Enter 10 numbers only.',
  DL_card_NT_invalid:'Invalid input. Enter 6 to 8 numbers only.',
  DL_card_QLD_invalid:'Invalid input. Enter 10 numbers and/or letters only.',
  DL_card_SA_invalid:'Invalid input. Enter 9 numbers and/or letters only.',
  DL_card_TAS_invalid:'Invalid input. Enter 9 numbers and/or letters only.',
  DL_card_VIC_invalid:'Invalid input. Enter 8 numbers and/or letters only.',
  DL_card_WA_invalid:'Invalid input. Enter 8 to 10 numbers and/or letters only.',
  DL_card_invalid: 'Invalid input. Format should be 3 letters followed by 6 numbers. No space or special characters.',
  MC_invalid: 'Invalid input. Enter 10 numbers only.',
  MC_DOE_invalid:'Please select a Card colour first then enter a valid expiry date. ',
  Invalid_NameLN: `Required. Please enter family name.`,
  Required_PN: `Required field.`,
  Required_idType: `Required field.`,
  Required_cardColor: `Please select a card colour.`,
  Required_stateOfIssue: `Please select a state.`,
  Required_issuingCountry: `Please select a country of issue.`,
  Required_cardNumber: `Required. Please enter card number.`,
  Required_documentNumber: `Required. Please enter document number.`,
  Required_documentNumber_DL: `Required. Please enter licence number.`,
  Required_documentNumberPP: `Required. Please enter your passport number.`,
  Required_expiryDate: `Required. Please enter expiry date.`,
  Required_dob: `Required. Please enter date of birth.`,
  Invalid_Date: `Entered year should not be less than 1900.`,
  Invalid_DateDOE: `This document is expired, check the expiry date or try another document.`,
  Invalid_Date_Format: `Invalid entry. Please enter valid date.`,
  Future_Date: `Future dates are not allowed.`,
  Date_Range: `The range must be seven days apart only.`,
  Start_Date_Greater: `The start date must come before the end date.`,
  Invalid_Startdate: `The start date provided is not valid, please enter a valid date.`,
  Invalid_Enddate: `The end date provided is not valid, please enter a valid date.`,
  No_Reports: `Only last 14 days scans are available for viewing.`,
  Invalid_Name: `Invalid entry. Your name must only contain the following characters: A-Z,- dot (.) or hyphen (-) or apostrophe (').`,
  Invalid_Card: `Please enter valid number`,
  Invalid_MedicCard: `In-valid entry. Card number must have 10 digits.`,
  Doc_Expired: `This document is expired, check the expiry date or try another document.`,
  Doc_Greater: `The document's expiry date cannot be greater than 10 years 6 months.`,
  Doc_Greater_10: `The document's expiry date cannot be greater than 10 years.`,
  Doc_Greater_5: `The document's expiry date cannot be greater than 5 years.`,
  Below_10: `Customer is under the age of 10 and cannot proceed.`,
  Over_125: `Customer is over the age of 125 and cannot proceed.`,
  Under_18: `Customer is under the age of 18. Do not offer any postpaid services.`,
  Invalid_Pnum: `Please select an individual reference number.`,
  Future_Year: `Invalid entry. Date of birth cannot be a future date.`,
  Past_Year: `Invalid entry. Date of expiry cannot be a past date.`,
  cid_login_cancel:"The user selected cancel at the login page",
  cid_aborted_cancel:"End-User aborted interaction",
  cid_declined_cancel:"The user declined to provide consent to share the attributes",
};

export const serverErrors = {
  err_default: {
    code: "err_default",
    heading: `Something went wrong`,
    subHeading: ``,
    content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
    content2: `Please try again.`,
    errDesc: `Please try again.`,
    errRefNo: "000",
    redirectTo: "ERR"
  },
  validateIDVRequest: {
    err_400_900: {
      code: "err_400_900",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `An exception occurred due to service unavailable or object un intialized`,
      errRefNo: "001",
      redirectTo: "ERR"
    },
    err_400_901: {
      code: "err_400_901",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Missing input parameter`,
      errRefNo: "002",
      redirectTo: "ERR"
    },
    err_400_902: {
      code: "err_400_902",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Loading the certificate and key in to the key store is failed`,
      errRefNo: "003",
      redirectTo: "ERR"
    },
    err_400_903: {
      code: "err_400_903",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Certificate conversion has been failed`,
      errRefNo: "004",
      redirectTo: "ERR"
    },
    err_400_904: {
      code: "err_400_904",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Keystore build failed`,
      errRefNo: "005",
      redirectTo: "ERR"
    },
    err_400_905: {
      code: "err_400_905",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Missing request header x-gateway-host for method parameter of type String`,
      errRefNo: "006",
      redirectTo: "ERR"
    },
    err_400_906: {
      code: "err_400_906",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Missing body information.`,
      errRefNo: "007",
      redirectTo: "ERR"
    },
    err_400_907: {
      code: "err_400_907",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Missing Authorization Bearer information`,
      errRefNo: "008",
      redirectTo: "ERR"
    },
    err_400_908: {
      code: "err_400_908",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Params provided in header and body are mismatched.`,
      errRefNo: "009",
      redirectTo: "ERR"
    },
    err_400_909: {
      code: "err_400_909",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Unable to decode token. Provided token could be tampered.`,
      errRefNo: "010",
      redirectTo: "ERR"
    },
    err_400_910: {
      code: "err_400_910",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `No records found for supplied token/referenceId`,
      errRefNo: "011",
      redirectTo: "ERR"
    },
    err_500_4000: {
      code: "err_500_4000",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Error occured while connecting to DB server`,
      errRefNo: "012",
      redirectTo: "ERR"
    },
    err_422_1050: {
      code: "err_422_1050",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Transaction has already completed,unable to proceed further`,
      errRefNo: "013",
      redirectTo: "gotoUrl"
    },
    err_422_1010: {
      code: "err_422_1010",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `IdvRequest has expired.`,
      errRefNo: "014",
      redirectTo: "gotoUrl"
    },
    err_422_1020: {
      code: "err_422_1020",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Params found in CAIMAN session are not matching with IDV Request.`,
      errRefNo: "015",
      redirectTo: "gotoUrl"
    },
  },
  validateIDVDocument: {
    err_400_900: {
      code: "err_400_900",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `An exception occurred due to service unavailable or object un intialized`,
      errRefNo: "001",
      redirectTo: "ERR"
    },
    err_400_901: {
      code: "err_400_901",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Missing input parameter`,
      errRefNo: "002",
      redirectTo: "ERR"
    },
    err_400_902: {
      code: "err_400_902",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Loading the certificate and key in to the key store is failed`,
      errRefNo: "003",
      redirectTo: "ERR"
    },
    err_400_903: {
      code: "err_400_903",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Certificate conversion has been failed`,
      errRefNo: "004",
      redirectTo: "ERR"
    },
    err_400_904: {
      code: "err_400_904",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Keystore build failed`,
      errRefNo: "005",
      redirectTo: "ERR"
    },
    err_400_905: {
      code: "err_400_905",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Missing request header x-gateway-host for method parameter of type String`,
      errRefNo: "006",
      redirectTo: "ERR"
    },
    err_400_911: {
      code: "err_400_911",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Supplied scanType is not valid`,
      errRefNo: "007",
      redirectTo: "ERR"
    },
    err_400_912: {
      code: "err_400_912",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Provided names should met with acceptance criteria`,
      errRefNo: "008",
      redirectTo: "ERR"
    },
    err_400_913: {
      code: "err_400_913",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Provided DOB (or) DOE should be in valid format`,
      errRefNo: "009",
      redirectTo: "ERR"
    },
    err_400_914: {
      code: "err_400_914",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Invalid DOB, Either over the age of 120 (or) under the age of 10`,
      errRefNo: "010",
      redirectTo: "ERR"
    },
    err_400_915: {
      code: "err_400_915",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Supplied idType is not valid`,
      errRefNo: "011",
      redirectTo: "ERR"
    },
    err_400_916: {
      code: "err_400_916",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Supplied StateOfIssue (or) IssuingCountry is not valid`,
      errRefNo: "012",
      redirectTo: "ERR"
    },
    err_400_917: {
      code: "err_400_917",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Provided DocumentNumber (or) CardNumber (or) IndividualReferenceNumber should  met with acceptance criteria`,
      errRefNo: "013",
      redirectTo: "ERR"
    },
    err_400_918: {
      code: "err_400_918",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Invalid DOE, This Document Has Expired`,
      errRefNo: "014",
      redirectTo: "ERR"
    },
    err_400_919: {
      code: "err_400_919",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Color Restrictions Applied for the provided document`,
      errRefNo: "015",
      redirectTo: "ERR"
    },
    err_422_1040: {
      code: "err_422_1040",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `For this user editing of Personal details is not allowed`,
      errRefNo: "016",
      redirectTo: "gotoUrl"
    },
    err_422_1030: {
      code: "err_422_1030",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `For this transaction, Supplied request is not matching with tokenId in DB`,
      errRefNo: "017",
      redirectTo: "gotoUrl"
    },
    err_422_1100: {
      code: "err_422_1100",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `CONNECTION_TIMEOUT(message from the exception)`,
      errRefNo: "018",
      redirectTo: "gotoUrl"
    },
    err_422_1110: {
      code: "err_422_1110",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `OKAPI received an error from the proxied upstream server`,
      errRefNo: "019",
      redirectTo: "gotoUrl"
    },
    err_422_1200: {
      code: "err_422_1200",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Multiple VIDs Found for this Customer and hence unable to process the request`,
      errRefNo: "020",
      redirectTo: "gotoUrl"
    },
    err_422_1210: {
      code: "err_422_1210",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `FamilyName did not match validation (or) Verification Id is not Valid / Active`,
      errRefNo: "021",
      redirectTo: "gotoUrl"
    },
    err_422_1220: {
      code: "err_422_1220",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Consent not captured.Consent must be given by the contact before submitting the request.`,
      errRefNo: "022",
      redirectTo: "gotoUrl"
    },
    err_422_1300: {
      code: "err_422_1300",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Error while calling Green ID / Error while retrieving documents`,
      errRefNo: "023",
      redirectTo: "gotoUrl"
    },
    err_422_8000: {
      code: "err_422_8000",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Dynamic exception`,
      errRefNo: "024",
      redirectTo: "gotoUrl"
    },
    err_422_1010: {
      code: "err_422_1010",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `IdvRequest has expired.`,
      errRefNo: "025",
      redirectTo: "gotoUrl"
    },
    err_422_1020: {
      code: "err_422_1020",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `Params found in CAIMAN session are not matching with IDV Request.`,
      errRefNo: "026",
      redirectTo: "gotoUrl"
    },
    err_422_2100: {
      code: "err_422_2100",
      heading: `Something went wrong`,
      subHeading: ``,
      content1: `We are unable to complete your request. Please try again or <a className="cnsbColor" rel="noreferrer" href='https://www.telstra.com.au/contact-us' target="_blank">message us </a> for help.`,
      content2: `Please try again.`,
      errDesc: `max attempts(3) reached with IDV verify api.`,
      errRefNo: "027",
      redirectTo: "gotoUrl"
    },
  },
  cidParToken:{
    err_400_6003: {
      code: "err_400_6003",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Missing par token parameters in header (or) request parmas`,
      errRefNo: "001",
      redirectTo: "ERR"
    },
    err_400_6002: {
      code: "err_400_6002",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Missing idv request parameters in create par`,
      errRefNo: "002",
      redirectTo: "ERR"
    },
    err_422_6100: {
      code: "err_422_6100",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Dynamic exception`,
      errRefNo: "003",
      redirectTo: "ERR"
    },
    err_422_6300: {
      code: "err_422_6300",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Dynamic exception`,
      errRefNo: "004",
      redirectTo: "ERR"
    },
    err_500_900: {
      code: "err_500_900",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `An exception occurred due to service unavailable or object un intialized`,
      errRefNo: "005",
      redirectTo: "ERR"
    },
    err_422_1510: {
      code: "err_422_1510",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Error while saving documents`,
      errRefNo: "006",
      redirectTo: "ERR"
    },
  },
  cidCreatePar:{
    err_400_6001: {
      code: "err_400_6001",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Missing body parameters in create par`,
      errRefNo: "001",
      redirectTo: "ERR"
    },
    err_400_6002: {
      code: "err_400_6002",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Missing idv request parameters in create par`,
      errRefNo: "002",
      redirectTo: "ERR"
    },
    err_400_6000: {
      code: "err_400_6000",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Invalid purpose supplied for transaction:`,
      errRefNo: "003",
      redirectTo: "ERR"
    },
    err_422_6100: {
      code: "err_422_6100",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Dynamic exception`,
      errRefNo: "004",
      redirectTo: "ERR"
    },
    err_422_6200: {
      code: "err_422_6200",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `Dynamic exception`,
      errRefNo: "005",
      redirectTo: "ERR"
    },
    
    err_500_900: {
      code: "err_500_900",
      heading: `We are unable to verify your identity via ConnectID`,
      subHeading: ``,
      content1: `Try another bank or verify manually with your ID document`,
      content2: `Please try again.`,
      errDesc: `An exception occurred due to service unavailable or object un intialized`,
      errRefNo: "007",
      redirectTo: "ERR"
    },
  }

};